<template>
	<div class="table-container">
		<table class="table">
			<thead class="table__header">
				<th class="table__header-cell" v-for="(headerObject,headerName) in header" :key="headerName">
						{{ columnHeaderName(headerObject,headerName) }} 
				</th>
			</thead>
			<tbody class="table__body" v-if="list.length">
				<tr class="table__row" v-for="(item, index) in list" :key="index"  @click="gotoDetails(item.id)">
					<td class="table__cell" v-for="(headerObject,headerName) in header" :key="headerName">
						<button class="table__button" :class="{'table__button--pending':item[headerName]}" v-if="pendingState(headerName)" @click.stop="updatePendingFalse(item.id)">
							<pre>{{ item[headerName] ? 'Pending' : 'Ready' }}</pre>
						</button>			
						<span v-else>{{ ( !item[headerName] && !( typeof item[headerName] === 'number') )  ? "-" : item[headerName] }}</span>
					</td>
					
				</tr>
				<tr>
					<td v-show="next" colspan="100%" @click="loadMoreData">
						<div class="table__row-button">
							<span v-if="loadingNext">loading...</span>	
							<span v-else>more +</span>
						</div> 
					</td>
				</tr>
			</tbody>
			<td v-else class="table__error" colspan="100%">"List is Empty"</td>
		</table>
	</div>
</template>
 
<script>
export default {
	name: 'Table',
	props:{list:Array,header:Object,next:String,loadingNext:Boolean},
	computed:{
		routeName(){
			return this.$route.name
		},
		isVendor(){
			return this.$store.state.user.user_type === 'Vendor'
		},
	},
	methods:{
		pendingState(headerName){
			return headerName === 'is_pending'
		},
		gotoDetails(id){
			console.log(this.$route.name==='transactions')
			if(this.$route.meta['OPTIONS'].state.name !== 'expenses' && this.$route.name !== 'transactions' && this.$route.name !== 'product-summary'){
				this.$router.push({name:`${this.routeName}-details`,params:{ id } }) //excuding expenses and transactions page for now
			} 
			
		},
		columnHeaderName(headerObject,headerName){
		
			if( typeof headerObject === 'object' && !Array.isArray(headerObject) && headerObject !== null) {
				return headerObject.substitute
				
			}else if(typeof headerObject === 'string'){
				return headerName
			}else{
				return 'wrong input'
			}
		},

		updatePendingFalse(id){
			if(this.isVendor){
				this.$store.dispatch({
					type:'orders/updatePendingFalse',
					id,
				}).then(response=>console.log(response))
				.catch(error=>console.log(error))
			}
			
		},
		loadMoreData(){
			if (!this.loadingNext) this.$emit('Load-more-data')
		}
	},
}
</script>

<style>
	.table-container{
		position: relative;
		background:#fff;
		max-height: 100%;
		overflow-x: scroll;
		box-shadow: var(--shadow-card);
		border-radius: var(--border-radius);
		
	}
	.table{
		min-width: 100%;
		border-collapse: collapse;
		font-size: 16px;
		
	}

	.table-container{
		-ms-overflow-style: auto;
		scrollbar-width: auto;
	}
	.table__row{
		padding: 1em;
		cursor: pointer;
		
		
	}

	

	.table-container::-webkit-scrollbar {
		display:block; 
		width: 5px;
		height: 5px;
	}
	.table-container::-webkit-scrollbar-thumb {
		background: #ddd;
		border-radius: 1em;
		opacity: .2;
	}	
	.table__row:nth-child(even){
		background-color: rgb(247,249,250); 
	}	

	.table__body{
		height: 100%;
	}

	.table__header{
		text-transform: capitalize;
		position: sticky;
		background: #fff;
		top:0;
		font-weight: bold;
		box-shadow: var(--shadow-card);
	}



	
	.table__cell,.table__header-cell{
		padding:1em;
		text-align: center;
		/* box-shadow:-3px 0 0 0 red; */
	}

	.table__row-button{
		cursor:pointer;
		padding:.5em;
		margin: 1em;
		text-align: center;
		border: var(--bg-dark) 1px dashed;
		border-radius: var(--border-radius);
	}

	.table__cell{
		word-wrap: break-word;
	}

	.table__button{
		font-size: .75em;
		min-width: 10ch;
		color:#fff;
		background: #4ad295;
		border:none;
		padding:0;
		padding: 4px 0;

		border-radius:1em;
	}

	.table__button--pending{
		background: var(--bg-danger);
	}

	.table__error{
		opacity: .6;
		text-align: center;
		font-style: italic;
		padding:1em 0;
	}
</style>