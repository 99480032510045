<template>
	<div class="content--loading" v-if="loading">
		<filling-circle color="#057DCD" :size="42" />
	</div>
    <div v-else-if="error">data does not exist</div>
	<div class="content__list" v-else> 
		<summary-table :header="header" :list="items" />
	</div>
	
</template>
<script>
import SummaryTable from '@/components/Table.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
    components:{
        SummaryTable,
        FillingCircle
    },
    data(){
        return {
            loading:true,
        }
    },
    computed:{
        header(){
			return this.$route.meta['RESPONSE_DATA_FILTER'].header
		},
        error(){
			return this.$store.state.error
		},
        range(){
            return this.$store.state.analysis.transactionsDateTimeRange
        },
        id(){
			return this.$route.params.id
		},

		getBranchId(){
			return this.$store.state.branchId
		},
        items(){
            return this.$store.state.analysis.productSummary
        }
    },

    methods:{
		setBranchId(){
			this.$store.commit({type:'setBranchId',branchId:this.id})
		},
	},

    watch: {
        // whenever question changes, this function will run
        
        range: function () {
            console.log(this.id)

			this.loading = true
            this.$store.dispatch({
                type:'analysis/loadProductSummary',
				id:this.id
            }).then((response)=>{
				this.loading=false
				console.log(response)
			}).
			catch((error)=>{
				this.loading=false
				console.error(error)
			})
        }
    },
    mounted(){
        

        this.$store.dispatch({type:'analysis/loadProductSummary',id:this.id}).then((response)=>{
			this.loading=false
			if(!this.getBranchId && this.id) this.setBranchId()
			console.log(response)
		}).
		catch((error)=>{
			this.loading=false
			console.error(error)
		})
    }
}

</script>

